import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
            </div>
        )
    }
}

export default Footer
