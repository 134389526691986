import React from 'react'

import Footer from './Footer'
import logo from '../assets/images/website-icon.png'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href={"/"} >
                  <img src={logo} className={'logo'} />
                  </a>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
